<template>
  <div>
    <div class="clear">
      <div class="banner clear">
        <img class="background" src="../assets/images/banbg.png" />
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in bannerList">
            <img
              :src="
                'https://dzyuyue2023.yougoo.com.cn/api/file/' +
                item.carouselFiles[0].type +
                '/' +
                item.carouselFiles[0].realName
              "
              alt=""
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="notice">
        公告板: 温州地质科普馆参观每周六上午<a @click="toGG">[查看详情]</a>
      </div>
      <div class="btns">
        <a @click="addVR">VR</a
        ><a
          @click="
            goSelected
          "
          >简介</a
        >
      </div>
      <ul class="nav com_flex1">
        <li>
          <a
            @click="
              $router.push(
                { path: '/introduce' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i1"></i>
            <p>参观预约</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/courseBooking' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i2"></i>
            <p>课程预约</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/travel' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i3"></i>
            <p>研学旅行</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/reservationPhone' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i4"></i>
            <p>我的预约</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/activityPreview' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i5"></i>
            <p>活动预告</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/activeReview' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i6"></i>
            <p>活动回顾</p></a
          >
        </li>
        <li>
          <a
            @click="
              $router.push(
                { path: '/selected' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            ><i class="i7"></i>
            <p>科普文选</p></a
          >
        </li>
        <li @click="$toast('敬请期待')">
          <a
            ><i class="i8"></i>
            <p>科普市集</p></a
          >
        </li>
      </ul>
      <div class="clear">
        <div class="titc">科普精选</div>
        <ul class="science_list">
          <li
            v-for="item in content"
            :key="item.contentId"
            @click="toDetails(item)"
          >
            <a>
              <div class="pic"><img :src="item.dataSource" /></div>
              <h3>{{ item.contentTitle }}</h3>
              <p>
                <em>{{ item.createTime }}</em
                ><em>温州地质科普馆</em>
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { contentList, carouselList } from "@/api/popularScience/index";

export default {
  data() {
    return {
      content: [],
      bannerList: [],
    };
  },
  created() {
    // 科普精选
    this.choiceness();
    this.getBanner();
  },
  methods: {
    // 科普精选
    async choiceness() {
      try {
        let from = {
          categoryId: 61,
          sort: "createTime,desc",
        };
        let res = await contentList(from);
        console.log(res);
        this.content = res.data.content;
      } catch (error) {
        console.log(error);
      }
    },
    async getBanner() {
      try {
        let res = await carouselList();
        this.bannerList = res.data.content;
      } catch (error) {
        console.log(error);
      }
    },
    // 查看公告详情
    toGG() {
      this.$router.push({ path: "/announcement" });
    },
    goSelected(){
      window.location.href = 'https://mp.weixin.qq.com/s/0lKbArDK2izCrRnOmfMaxg'
    },
    // 跳转详情
    toDetails(item) {
      if (item.urlLink) {
        window.location.href =item.urlLink
      } else {
        this.$router.push({
          path: "/selectedDel",
          query: { id: item.contentId },
        });
      }
    },
    // 点击vr
    addVR() {
      this.$router.push({
        path: "/skipPage",
        query: { url: "https://dzyuyue2023.yougoo.com.cn/vr/" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 366;
  height: 85vw;
}
.my-swipe {
  height: 85vw;
}
.my-swipe .van-swipe-item {
  // width: 100%;
  height: 85vw;
}
</style>