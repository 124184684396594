<template>
  <div class="fnav">
    <div class="fnav-fixed">
      <ul>
        <li>
          <a class="t1" @click="flang = !flang">展馆</a>
          <div :style="'display:' + (flang ? 'block' : 'none')" class="txt" style="color:#fff">
            <a>VR</a>
            <a   @click="
                $router.push(
                  { path: '/skipPage',query:{url:'https://dzyuyue2023.yougoo.com.cn/vr/'}},
                  (onComplete) => {},
                  (onAbort) => {}
                )
              ">简介</a>
            <a
              @click="
                $router.push(
                  { path: '/announcement' },
                  (onComplete) => {},
                  (onAbort) => {}
                )
              "
              >公告</a
            >
          </div>
        </li>
        <li><a class="t1"  @click="
              $router.push(
                { path: '/reservationPhone' },
                (onComplete) => {},
                (onAbort) => {}
              )
            ">预约</a></li>
        <li>
          <a
            class="t1"
            @click="
              $router.push(
                { path: '/' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            >首页</a
          >
        </li>
        <li>
          <a
            class="t1"
            @click="
              $router.push(
                { path: '/popularScience' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            >精选</a
          >
        </li>
        <li>
          <a
            class="t1"
            @click="
              $router.push(
                { path: '/contactUs' },
                (onComplete) => {},
                (onAbort) => {}
              )
            "
            >联系</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flang: false,
    };
  },
  methods: {
    // 点击vr
    addVR() {
      this.$router.push({
        path: "/skipPage",
        query: { url: "https://dzyuyue2023.yougoo.com.cn/vr/" },
      });
    },
  }
};
</script>

<style>
</style>