import request from '@/utils/request'

export function contentList(params) {
    return request({
        url: 'api/content/list',
        method: 'GET',
        params
    })
}
export function carouselList(params) {
    return request({
        url: '/api/carousel/list',
        method: 'GET',
        params
    })
}

export default { contentList ,carouselList }