<template>
  <div id="app">
    <router-view />
    <fnav v-if="$route.name != 'skipPage' && $route.name != 'courseSignUp' && $route.name != 'register' && $route.name != 'reservationPhone'" ></fnav>
  </div>
</template>
<script>
import fnav from "@/components/fnav/index.vue";
export default {
  components: {
    fnav,
  },

};
</script>


<style lang="less">
@import "./assets/css/css.css";
</style>
