import axios from 'axios'
// import Cookies from 'js-cookie'
import { Toast } from 'vant';

console.log(process.env.BASE_URL)
    // create an axios instance
const service = axios.create({
    baseURL: 'https://dzyuyue2023.yougoo.com.cn/api/', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})
service.interceptors.request.use(
    config => {
       
  
      return config
    },
    error => {
      Promise.reject(error)
    }
  )


// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        const res = response.data

        // if the custom code is not 200, it is judged as an error.
        // 请求静态资源时，返回的code为空，不抛出错误
        if (res.code !== 20000 && res.code !== '200' && typeof res.code !== 'undefined') {
            Toast.fail(res.msg);
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
           
            return res

        }
    },
    error => {
        console.log(error.response.data.message)
        Toast(error.response.data.message);
        return Promise.reject(error)
    }
)

export default service