import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 科普精选
  {
    path: '/popularScience',
    name: 'popularScience',
    component: () => import(/* webpackChunkName: "about" */ '../views/popularScience/index.vue')
  },
  // 参观预约
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import(/* webpackChunkName: "about" */ '../views/visit/introduce.vue')
  },
  // 预约登记
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/visit/register.vue')
  },
  // 广告详情
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import(/* webpackChunkName: "about" */ '../views/announcement.vue')
  },
  //简介
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/introduction.vue')
  },
    //手机预约
    {
      path: '/reservationPhone',
      name: 'reservationPhone',
      component: () => import(/* webpackChunkName: "about" */ '../views/reservation/reservationPhone.vue')
    },
  // 活动预告
  {
    path: '/activityPreview',
    name: 'activityPreview',
    component: () => import(/* webpackChunkName: "about" */ '../views/activityPreview/index.vue')
  },
   // 活动回顾
   {
    path: '/activeReview',
    name: 'activeReview',
    component: () => import(/* webpackChunkName: "about" */ '../views/activeReview/index.vue')
  },
  
  // 课程预约
  {
    path: '/courseBooking',
    name: 'courseBooking',
    component: () => import(/* webpackChunkName: "about" */ '../views/courseBooking/index.vue')
  },
  // 课程预约详情
  {
    path: '/courseDetails',
    name: 'courseDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/courseBooking/details.vue')
  },
  // 课程预约登记
  {
    path: '/courseSignUp',
    name: 'courseSignUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/courseBooking/signUp.vue')
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
  },
  // 科普文选
  {
    path: '/selected',
    name: 'selected',
    component: () => import(/* webpackChunkName: "about" */ '../views/selected/index.vue')
  },
  {
    path: '/selectedDel',
    name: 'selectedDel',
    component: () => import(/* webpackChunkName: "about" */ '../views/selected/details.vue')
  },
  {
    path: '/travel',
    name: 'travel',
    component: () => import(/* webpackChunkName: "about" */ '../views/travel/index.vue')
  },
  {
    path: '/travelDel',
    name: 'travelDel',
    component: () => import(/* webpackChunkName: "about" */ '../views/travel/details.vue')
  },
  // url地址页面
  {
    path: '/skipPage',
    name: 'skipPage',
    component: () => import(/* webpackChunkName: "about" */ '../skipPage.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
