var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"clear"},[_c('div',{staticClass:"banner clear"},[_c('img',{staticClass:"background",attrs:{"src":require("../assets/images/banbg.png")}}),_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000,"indicator-color":"white"}},_vm._l((_vm.bannerList),function(item){return _c('van-swipe-item',[_c('img',{attrs:{"src":'https://dzyuyue2023.yougoo.com.cn/api/file/' +
              item.carouselFiles[0].type +
              '/' +
              item.carouselFiles[0].realName,"alt":""}})])}),1)],1),_c('div',{staticClass:"notice"},[_vm._v(" 公告板: 温州地质科普馆参观每周六上午"),_c('a',{on:{"click":_vm.toGG}},[_vm._v("[查看详情]")])]),_c('div',{staticClass:"btns"},[_c('a',{on:{"click":_vm.addVR}},[_vm._v("VR")]),_c('a',{on:{"click":_vm.goSelected}},[_vm._v("简介")])]),_c('ul',{staticClass:"nav com_flex1"},[_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/introduce' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i1"}),_c('p',[_vm._v("参观预约")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/courseBooking' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i2"}),_c('p',[_vm._v("课程预约")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/travel' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i3"}),_c('p',[_vm._v("研学旅行")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/reservationPhone' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i4"}),_c('p',[_vm._v("我的预约")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/activityPreview' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i5"}),_c('p',[_vm._v("活动预告")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/activeReview' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i6"}),_c('p',[_vm._v("活动回顾")])])]),_c('li',[_c('a',{on:{"click":function($event){_vm.$router.push(
              { path: '/selected' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_c('i',{staticClass:"i7"}),_c('p',[_vm._v("科普文选")])])]),_c('li',{on:{"click":function($event){return _vm.$toast('敬请期待')}}},[_vm._m(0)])]),_c('div',{staticClass:"clear"},[_c('div',{staticClass:"titc"},[_vm._v("科普精选")]),_c('ul',{staticClass:"science_list"},_vm._l((_vm.content),function(item){return _c('li',{key:item.contentId,on:{"click":function($event){return _vm.toDetails(item)}}},[_c('a',[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":item.dataSource}})]),_c('h3',[_vm._v(_vm._s(item.contentTitle))]),_c('p',[_c('em',[_vm._v(_vm._s(item.createTime))]),_c('em',[_vm._v("温州地质科普馆")])])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"i8"}),_c('p',[_vm._v("科普市集")])])
}]

export { render, staticRenderFns }