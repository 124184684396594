var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fnav"},[_c('div',{staticClass:"fnav-fixed"},[_c('ul',[_c('li',[_c('a',{staticClass:"t1",on:{"click":function($event){_vm.flang = !_vm.flang}}},[_vm._v("展馆")]),_c('div',{staticClass:"txt",staticStyle:{"color":"#fff"},style:('display:' + (_vm.flang ? 'block' : 'none'))},[_c('a',[_vm._v("VR")]),_c('a',{on:{"click":function($event){_vm.$router.push(
                { path: '/skipPage',query:{url:'https://dzyuyue2023.yougoo.com.cn/vr/'}},
                (onComplete) => {},
                (onAbort) => {}
              )}}},[_vm._v("简介")]),_c('a',{on:{"click":function($event){_vm.$router.push(
                { path: '/announcement' },
                (onComplete) => {},
                (onAbort) => {}
              )}}},[_vm._v("公告")])])]),_c('li',[_c('a',{staticClass:"t1",on:{"click":function($event){_vm.$router.push(
              { path: '/reservationPhone' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_vm._v("预约")])]),_c('li',[_c('a',{staticClass:"t1",on:{"click":function($event){_vm.$router.push(
              { path: '/' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_vm._v("首页")])]),_c('li',[_c('a',{staticClass:"t1",on:{"click":function($event){_vm.$router.push(
              { path: '/popularScience' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_vm._v("精选")])]),_c('li',[_c('a',{staticClass:"t1",on:{"click":function($event){_vm.$router.push(
              { path: '/contactUs' },
              (onComplete) => {},
              (onAbort) => {}
            )}}},[_vm._v("联系")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }